//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDn0jY0u1Ke5_5xskdZHzoArFKapeX_X-U",
  authDomain: "bone-3d059.firebaseapp.com",
  projectId: "bone-3d059",
  storageBucket: "bone-3d059.firebasestorage.app",
  messagingSenderId: "94787207731",
  appId: "1:94787207731:web:f99a50311c04d9425cd946",
  measurementId: "G-C1YJK6JTCW"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
